import React, { Component } from "react";

export default class Sub1 extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="sub1 flex-1 flex-row flex-column">
        <div className="sub1-wrap">
          <div className="left flex-1">
            <div className="img-wrap flex-row flex-1 flex-center justify-center">
              <img
                src={require("../../../img/main/sub1/aboutus.png")}
                alt="about-us-img"
                className="responsive-img desk"
              />
              <img
                src={require("../../../img/main/sub1/aboutus_m.png")}
                alt="about-us-img"
                className="responsive-img mobile"
              />
            </div>
          </div>
          <div className="right flex-center justify-center flex-row flex-1">
            <article>
              <p className="font-bold title">{string.aboutUs}</p>
              <div>
                <span className="article">
                  {`${string.aboutUsDesc}`}
                  <br /> <br />
                </span>
                <span className="article">{`${string.flata}`}</span>
              </div>
            </article>
          </div>
        </div>
        <div className="sub1-wrap">
          <div className="left flex-center justify-center flex-row">
            <article>
              <p className="font-bold title">{`${string.blobsPlatform}`}</p>
              <div>
                <span className="article">
                  {`${string.blobsPlatformArticle}`}
                </span>
              </div>
            </article>
          </div>
          <div className="right flex-center justify-center flex-row flex-1">
            <div className="img-wrap">
              <img
                src={require("../../../img/main/sub1/blobsplatform.png")}
                alt="blobs-platform-img"
                className="responsive-img desk"
              />
              <img
                src={require("../../../img/main/sub1/blobsplatform_m.png")}
                alt="blobs-platform-img"
                className="responsive-img mobile"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
