import React, { Component } from "react";

export default class Sub2 extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="sub2">
        <div className="blobs-service">
          <div className="blobs-service-container">
            <div className="left">
              <img
                src={require("../../../img/main/sub2/blobsservice.png")}
                alt="blobs-service-img"
              />
            </div>
            <div className="right flex-center flex-row justify-center">
              <article>
                <p className="title white-text">{string.blobsService}</p>
                <div>
                  <span className="article white-text">
                    {string.blobsServiceDesc}
                  </span>
                </div>
              </article>
            </div>
          </div>
        </div>
        <div className="blobs-coin">
          <p className="title white-text font-bold">{string.menuBlobsCoin}</p>
          <p>
            <span className="article white-text">{string.blobsCoinDesc}</span>
          </p>
          <div className="blobs-coin-description">
            <div className="table-body flex-row flex-column justify-center flex-center">
              <img
                src={require("../../../img/main/sub2/blobscoin1.png")}
                alt="blobs-coin-description-1"
              />
              <span className="table-body-title white-text">
                {string.blobsAPI}
              </span>
              <div className="table-body-article white-text">
                {string.blobsAPIDesc}
              </div>
            </div>
            <div className="table-body flex-row flex-column justify-center flex-center">
              <img
                src={require("../../../img/main/sub2/blobscoin2.png")}
                alt="blobs-coin-description-2"
              />
              <span className="table-body-title white-text">
                {string.payService}
              </span>
              <div className="table-body-article white-text">
                {string.payServiceDesc}
              </div>
            </div>
            <div className="table-body flex-row flex-column justify-center flex-center">
              <img
                src={require("../../../img/main/sub2/blobscoin3.png")}
                alt="blobs-coin-description-3"
              />
              <span className="table-body-title white-text">
                {string.flataCoop}
              </span>
              <div className="table-body-article white-text">
                {string.flataCoopDesc}
              </div>
            </div>
            <div className="table-body flex-row flex-column justify-center flex-center">
              <img
                src={require("../../../img/main/sub2/blobscoin4.png")}
                alt="blobs-coin-description-4"
              />
              <span className="table-body-title white-text">
                {string.blobsCoinMining}
              </span>
              <div className="table-body-article white-text">
                {string.blobsCoinMiningDesc}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
