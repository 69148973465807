import React, { Component } from "react";
import { Link } from "react-router-dom";
import { httpGet, httpUrl } from "../../api/httpclient";
import YouTube from 'react-youtube';
import { connect } from "react-redux";

class Intro extends Component {
  state = {
    noticeList: [],
    eventList: []
  };

  getBoardList = type => {
    httpGet(httpUrl.boardList, [5, 1, type], {}).then(res => {
      if (type === "NOTE") {
        this.setState({
          noticeList: res.data.data
        });
      } else {
        this.setState({
          eventList: res.data.data
        });
      }
    });
  };

  componentDidMount() {
    this.getBoardList("NOTE");
    this.getBoardList("EVENT");
  }

  render() {
    const { noticeList, eventList } = this.state;
    const { string } = this.props;
    const linkList = [
      {
        title: string.blobsWhitePaper,
        img: require("../../img/icon/whitepaper.png"),
        value: "whitepaper",
        linkTo: "https://blobscoin.io/blobscoin.pdf"
      },
      {
        title: string.blobsBlog,
        img: require("../../img/icon/blog.png"),
        value: "blog",
        linkTo: "http://blog.naver.com/blobs"
      },
      {
        title: string.blobsKakao,
        img: require("../../img/icon/kakao.png"),
        value: "kakao",
        linkTo: "https://open.kakao.com/o/gzMTghPb"
      },
      {
        title: string.blobsTelegram,
        img: require("../../img/icon/telegram.png"),
        value: "telegram",
        linkTo: "https://t.me/blobscoin"
      },
      {
        title: string.blobsB2B,
        img: require("../../img/icon/B2B.png"),
        value: "B2B",
        linkTo: "https://solution.blobs.co.kr/"
      },
      {
        title: string.blobsCard,
        img: require("../../img/icon/blobscard.png"),
        value: "blobscard",
        linkTo: "https://card.blobs.co.kr/"
      }
    ];
    return (
      <section>
        <div className="intro">
          <div className="non-height-container">
            {/* <div className="intro-wrap m-b-4">
              <div className="intro-wrap-list">
                <p className="pointer">
                  <Link to="/board/NOTE">
                    <span className="font-20 font-bold basic-text">
                      {string.menuBoardNotice}
                    </span>
                  </Link>
                </p>
                {noticeList.length !== 0 ? (
                  <ul>
                    {noticeList.map((n, index) => (
                      <Link to={`/board/NOTE/${n.idx}`} className="basic-text">
                        <li
                          key={`notice`}
                          className={index === 0 ? "font-bold" : ""}>
                          {n.title}
                        </li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  <span>{string.noBoardList}</span>
                )}
              </div>
              <div className="intro-wrap-list">
                <p className="pointer">
                  <Link to="/board/EVENT">
                    <span className="font-20 font-bold basic-text">
                      {string.menuBoardEvent}
                    </span>
                  </Link>
                </p>
                {eventList.length !== 0 ? (
                  <ul>
                    {eventList.map((e, index) => (
                      <Link to={`/board/EVENT/${e.idx}`} className="basic-text">
                        <li
                          key={`event`}
                          className={index === 0 ? "font-bold" : ""}>
                          {e.title}
                        </li>
                      </Link>
                    ))}
                  </ul>
                ) : (
                  <span>{string.noEventList}</span>
                )}
              </div>
            </div> */}
          </div>
          <div className="intro-body">
            <div className="video">
              <div className="non-height-container">
                <div className="desk">
                  <YouTube
                    videoId={'qvL7JMwVk9s'}
                    opts={{
                      width: 1284,
                      height: 722,
                      playerVars: { // https://developers.google.com/youtube/player_parameters
                        autoplay: 0
                      }
                    }}
                  />
                </div>
                <div className="mobile">
                  <YouTube
                    videoId={'qvL7JMwVk9s'}
                    opts={{
                      width: 338,
                      height: 190,
                      playerVars: { // https://developers.google.com/youtube/player_parameters
                        autoplay: 0
                      }
                    }}
                  />
                </div>
                {/* <div className="video-wrap">
                  <span className="white-text">{string_kr.readyToVideo}</span>
                </div> */}
              </div>
            </div>
            <div className="non-height-container">
              <div className="link flex-row flex-sb">
                {linkList.map((link, index) => (
                  <div key={`link${index}`} className="link-wrap">
                    <div className="flex-row flex-center left">
                      <div className="img-wrap">
                        <img
                          src={link.img}
                          alt={link.value}
                          className="responsive-img"
                        />
                      </div>
                      <div className="title">{link.title}</div>
                    </div>
                    <div
                      className="right pointer"
                      onClick={() => {
                        window.open(`${link.linkTo}`);
                      }}>
                      <img
                        src={require("../../img/icon/gobtn.png")}
                        alt={`${link.value}-link-btn`}
                        className="responsive-img"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

export default connect(mapStateToProps)(Intro);
