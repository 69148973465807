import React, { Component } from "react";

export default class Sub3 extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="sub3 flex-row flex-column">
        <div className="sub3-title">
          <span className="title font-bold white-text">{string.vision}</span>
        </div>
        <div className="flex-row table">
          <div className="table-row">
            <div className="table-row-title">
              <span className="title font-bold white-text">
                {string.profitShare}
              </span>
            </div>
            <div className="table-row-article">
              <span className="white-text article">
                {string.profitShareDesc1}
                <br /> {string.profitShareDesc2}
              </span>
            </div>
          </div>
          <div className="table-row">
            <div className="table-row-title">
              <span className="title font-bold white-text">
                {string.global}
              </span>
            </div>
            <div className="table-row-article">
              <span className="white-text article">{string.globalDesc}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
