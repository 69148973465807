import React, { Component } from "react";
import { Route, Switch, withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Typography } from "antd";

import Navigation from "./Navigation";
import HeaderCoin from "../pages/header/HeaderCoin";
import HeaderBoard from "../pages/header/HeaderBoard";

const { Text } = Typography;

class HeaderIntro extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="white-text t-a-c header-intro flex-column justify-center flex-center">
        {/* <div
          className="desk container"
          style={{
            position: "absolute",
            zIndex: 100,
            width: "100%",
            height: "auto",
            top: "388px",
            justifyContent: "center",
            paddingRight: "58px",
          }}>
          <img src={require("../img/icon/phone.gif")} />
        </div>
        <div
          className="mobile container"
          style={{
            position: "absolute",
            zIndex: 100,
            width: "100%",
            height: "auto",
            top: "228px",
            justifyContent: "center",
            paddingRight: "58px",
          }}>
          <img
            src={require("../img/icon/phone.gif")}
            style={{ width: "262px" }}
          />
        </div> */}
        <section style={{ marginTop: 0 }}>
          <div className="font-48 font-bold title desk">
            {string.introTitle}
          </div>

          <p>{string.introDescription}</p>
          <p>{string.introDescription2}</p>
          <Link to="/blobscoin">
            <div className="more-btn flex-row flex-center justify-center pointer">
              <div className="">
                <Text>{string.moreBtn}</Text>
              </div>
            </div>
          </Link>
        </section>
        <div className="header-intro-icon-wrap">
          <img
            src={require("../img/icon/phone.gif")}
            alt="intro-icon"
            className="responsive-img"
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setString.language,
    string: state.setString.string,
  };
};

HeaderIntro = connect(mapStateToProps)(HeaderIntro);

class Header extends Component {
  render() {
    const { location } = this.props;
    let path =
      location.pathname === "/"
        ? "intro"
        : location.pathname === "/blobscoin"
        ? "coin"
        : "board";

    return (
      <header id="header" className={`header-${path}-background`}>
        <div className="container">
          <Navigation />
          <Switch>
            <Route exact path="/" component={HeaderIntro} />
            <Route exact path="/blobscoin" component={HeaderCoin} />
            <Route exact path="/board/:name" component={HeaderBoard} />
            <Route exact path="/board/:name/:idx" component={HeaderBoard} />
          </Switch>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);
