import React, { Component } from "react";

export default class Sub4 extends Component {
  state = {};

  render() {
    const { string } = this.props;
    const members = [
      {
        name: string.member4,
        linkedin: "https://www.linkedin.com/in/rhs4",
        description: (
          <span>
            {string.member1Desc1}
            <br />
            {string.member4Desc2}
          </span>
        ),
        img: require("../../../img/main/sub4/member_hyungseokryu.png"),
        m_img: require("../../../img/main/sub4/member_hyungseokryu_m.png"),
      },
      {
        name: string.member5,
        linkedin: "https://www.linkedin.com/in/lsh-580022195",
        description: (
          <span>
            {string.member5Desc1}
            <br />
            {string.member5Desc2}
          </span>
        ),
        img: require("../../../img/main/sub4/member_seunghaklee.png"),
        m_img: require("../../../img/main/sub4/member_seunghaklee_m.png"),
      },
    ];
    const advisor1 = [
      {
        name: string.advisor2,
        description: (
          <span>
            {string.advisor2Desc1}
            <br />
            {string.advisor2Desc2}
            <br />
            {string.advisor2Desc3}
            <br />
            {string.advisor2Desc4}
            <br />
            {string.advisor2Desc5}
            <br />
            {string.advisor2Desc6}
            <br />
            {string.advisor2Desc7}
          </span>
        ),
        img: require("../../../img/main/sub4/member_taesikshin.png"),
        m_img: require("../../../img/main/sub4/member_taesikshin_m.png"),
      },
      {
        name: string.advisor3,
        description: (
          <span>
            {string.advisor3Desc1}
            <br />
            {string.advisor3Desc2}
            <br />
            {string.advisor3Desc3}
            <br />
            {string.advisor3Desc4}
            <br />
            {string.advisor3Desc5}
            <br />
            {string.advisor3Desc6}
            <br />
            {string.advisor3Desc7}
          </span>
        ),
        img: require("../../../img/main/sub4/member_yeongdaepark.png"),
        m_img: require("../../../img/main/sub4/member_yeongdaepark_m.png"),
      },
    ];
    const advisor2 = [
    
      {
        name: string.advisor5,
        description: (
          <span>
            {string.advisor5Desc1}
            <br />
            {string.advisor5Desc2}
          </span>
        ),
        img: require("../../../img/main/sub4/member_dongholee.png"),
        m_img: require("../../../img/main/sub4/member_dongholee_m.png"),
      },
      {
        name: string.advisor7,
        description: (
          <span>
            {string.advisor7Desc1}
            <br />
            {string.advisor7Desc2}
          </span>
        ),
        img: require("../../../img/main/sub4/member_mirakim.png"),
        m_img: require("../../../img/main/sub4/member_mirakim_m.png"),
      },
      {
        name: string.advisor8,
        description: (
          <span>
            {string.advisor8Desc1}
            <br />
            {string.advisor8Desc2}
          </span>
        ),
        img: require("../../../img/main/sub4/member_dongkwoncho.png"),
        m_img: require("../../../img/main/sub4/member_dongkwoncho_m.png"),
      },
    ];
    return (
      <div className="sub4 flex-row flex-1 flex-column">
        <div className="section1 flex-row flex-1 flex-column p-y-60">
          <div className=" section1-title flex-row flex-center justify-center">
            <span className="title font-bold">{string.coinDist}</span>
          </div>
          <div className="flex-row section1-content justify-center">
            <span className="article">
              {string.coinDistDesc1}
              <br /> {string.coinDistDesc2}
              <br />
              {string.coinDistDesc3}
            </span>
          </div>
          <div className="section1-img-wrap flex-row flex-column flex-center">
            <div className="chart-wrap">
              <img
                src={require("../../../img/main/sub4/Chart.png")}
                alt="chart-img"
                className="responsive-img"
              />
            </div>
            <div className="chart-labels flex-row flex-center">
              <div className="label-wrap flex-row flex-center">
                <div className="color" style={{ backgroundColor: "#4346d3" }} />
                <span>{string.chartLabel1}</span>
              </div>
              <div className="label-wrap flex-row flex-center">
                <div className="color" style={{ backgroundColor: "#42e8e0" }} />
                <span>{string.chartLabel2}</span>
              </div>
              <div className="label-wrap flex-row flex-center">
                <div className="color" style={{ backgroundColor: "#e1e6ef" }} />
                <span>{string.chartLabel3}</span>
              </div>
              <div className="label-wrap flex-row flex-center">
                <div className="color" style={{ backgroundColor: "#2d99ff" }} />
                <span>{string.chartLabel4}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="section2">
          <div className="container">
            <div className=" section2-title flex-row flex-center justify-center">
              <span className="title font-bold">{string.roadMap}</span>
            </div>
            <div className="roadmap desk flex-row flex-1 flex-center justify-center m-t-60">
              <div className="img-wrap flex-1 flex-row justify-center flex-center">
                <div className="flex-row flex-column flex-sb left">
                  <div className="road">
                    <span>18.03 {string.roadMap1}</span>
                  </div>
                  <div className="road">
                    <span>18.09 {string.roadMap2}</span>
                  </div>
                  <div className="road">
                    <span>18.10 {string.roadMap3}</span>
                  </div>
                  <div className="road">
                    <span>18.11 {string.roadMap4}</span>
                  </div>
                  <div className="road">
                    <span>19.03 {string.roadMap5}</span>
                  </div>
                  <div className="road">
                    <span>19.07 {string.roadMap6}</span>
                  </div>
                  <div className="road">
                    <span>19.08 {string.roadMap7}</span>
                  </div>
                  <div className="road">
                    <span>19.12 {string.roadMap8}</span>
                  </div>
                  <div className="road">
                    <span>20.01 {string.roadMap9}</span>
                  </div>
                  <div className="road">
                    <span>20.02 {string.roadMap10}</span>
                  </div>
                </div>
                <img
                  src={require("../../../img/main/sub4/roadmap.png")}
                  alt="roadmap"
                  className="responsive-img"
                />
                <div className="flex-row flex-column flex-sb right">
                  <div className="road">
                    <div className="date">20 1Q</div>
                    {string.roadMap11}
                  </div>
                  <div className="road">
                    <div className="date">20 2Q</div>
                    {string.roadMap12}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    {string.roadMap13}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    {string.roadMap14}
                  </div>
                  <div className="road">
                    <div className="date">20 3Q</div>
                    {string.roadMap15}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    {string.roadMap16}
                  </div>
                  <div className="road">
                    <div className="date">20 4Q</div>
                    {string.roadMap17}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    {string.roadMap18}
                  </div>
                  <div className="road">
                    <div className="date">21</div>
                    {string.roadMap19}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    {string.roadMap20}
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    <span style={{ textAlign: "right" }}>
                      {string.roadMap21}
                      <br />
                    </span>
                  </div>
                  <div className="road">
                    <div className="date"></div>
                    <span>{string.roadMap22}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="roadmap mobile">
              <div className="left">
                <img
                  src={require("../../../img/main/sub4/roadmap_m.png")}
                  alt="roadmap"
                  className="responsive-img"
                />
              </div>
              <div className="right">
                <div className="road">
                  <div className="date">
                    <span>18.03</span>
                  </div>
                  <span>{string.roadMap1}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>18.09</span>
                  </div>
                  <span>{string.roadMap2}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>18.10</span>
                  </div>
                  <span>{string.roadMap3}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>18.11</span>
                  </div>
                  <span>{string.roadMap4}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>19.03</span>
                  </div>
                  <span>{string.roadMap5}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>19.07</span>
                  </div>
                  <span>{string.roadMap6}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>19.08</span>
                  </div>
                  <span>{string.roadMap7}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>19.12</span>
                  </div>
                  <span>{string.roadMap8}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20.01</span>
                  </div>
                  <span>{string.roadMap9}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20.02</span>
                  </div>
                  <span>{string.roadMap10}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20 1Q</span>
                  </div>
                  <span>{string.roadMap11}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20 2Q</span>
                  </div>
                  <span>{string.roadMap12}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap13}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap14}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20 3Q</span>
                  </div>
                  <span>{string.roadMap15}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap16}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>20 4Q</span>
                  </div>
                  <span>{string.roadMap17}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap18}</span>
                </div>
                <div className="road">
                  <div className="date">
                    <span>21</span>
                  </div>
                  <span>{string.roadMap19}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap20}</span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span style={{ textAlign: "right" }}>
                    {string.roadMap21}
                    <br />
                  </span>
                </div>
                <div className="road">
                  <div className="date"></div>
                  <span>{string.roadMap22}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="section3">
          <div className="container">
            <div className=" section3-title flex-row flex-center justify-center">
              <span className="title font-bold p-y-60">
                {string.boardMemberTitle}
              </span>
            </div>
            <div className="section3-content flex-sa">
              {members.map((member) => (
                <div className="member">
                  <div className="member-name flex-row flex-center">
                    <div className="img-wrap">
                      <img src={member.img} alt="member-img" className="desk" />
                      <img
                        src={member.m_img}
                        alt="member-img"
                        className="mobile"
                      />
                    </div>
                    <span>{member.name}</span>
                    {member.linkedin && (
                      <div
                        style={{
                          position: "relative",
                          zIndex: 100,
                          marginLeft: 30,
                        }}>
                        <a href={member.linkedin} target="_blank">
                          <img
                            src={require("../../../img/icon/linkedin.svg")}
                            width={30}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="member-description">{member.description}</div>
                </div>
              ))}
            </div>
            <div className=" section3-title flex-row flex-center justify-center">
              <span className="title font-bold p-y-60">
                {string.advisorMeberTitle}
              </span>
            </div>
            <div className="section3-content">
              {advisor1.map((member) => (
                <div className="advisor">
                  <div className="advisor-name flex-row flex-center">
                    <div className="img-wrap">
                      <img src={member.img} alt="member-img" className="desk" />
                      <img
                        src={member.m_img}
                        alt="advisor-img"
                        className="mobile"
                      />
                    </div>
                    <span>{member.name}</span>
                  </div>
                  <div className="advisor-description">
                    {member.description}
                  </div>
                </div>
              ))}
            </div>
            <div className=" section3-title flex-row flex-center justify-center">
              <span className="title font-bold p-y-60">
                {string.advisorMeberTitle}
              </span>
            </div>
            <div className="section3-content">
              {advisor2.map((member) => (
                <div className="member2">
                  <div className="member-name flex-row flex-center">
                    <div className="img-wrap">
                      <img src={member.img} alt="member-img" className="desk" />
                      <img
                        src={member.m_img}
                        alt="member-img"
                        className="mobile"
                      />
                    </div>
                    <span>{member.name}</span>
                  </div>
                  <div className="member-description">{member.description}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
