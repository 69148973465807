import React, { Component } from "react";
import Sub1 from "./sub/Sub1";
import Sub2 from "./sub/Sub2";
import Sub3 from "./sub/Sub3";
import Sub5 from "./sub/Sub5";
import Sub4 from "./sub/Sub4";
import { connect } from "react-redux";

class BlobsCoin extends Component {
  render() {
    const { string } = this.props;
    return (
      <main>
        <div className="main__sub1">
          <div className="container">
            <Sub1 string={string} />
          </div>
        </div>
        <div className="main__sub2">
          <div className="container">
            <Sub2 string={string} />
          </div>
        </div>
        <div className="main__sub3">
          <div className="container">
            <Sub3 string={string} />
          </div>
        </div>
        <Sub4 string={string} />
        <div className="main__sub5">
          <div className="container">
            <Sub5 string={string} />
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

export default connect(mapStateToProps)(BlobsCoin);
