import { combineReducers } from "redux";
import { SET_LANGUAGE } from "../actions";

import korean from "../string_kr";
import english from "../string_en";
import chinese from "../string_cn";

const initState = {
  language: "ko",
  string: korean
};

const setString = (state = initState, action) => {
  switch (action.type) {
    case SET_LANGUAGE:
      return Object.assign(
        {},
        {
          language: action.language,
          string:
            action.language === "ko"
              ? korean
              : action.language === "en"
              ? english
              : chinese
        }
      );
    default:
      return state;
  }
};

const reducer = combineReducers({ setString });

export default reducer;
