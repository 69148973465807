import React, { Component } from "react";
import { Menu, Icon, Dropdown } from "antd";
import { Menu as MobileMenu, NavBar } from "antd-mobile";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
import { setLanguage } from "../actions";

const { SubMenu } = Menu;

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current: "",
      selectedLan: "ko",

      drawerOpen: false
    };
  }

  handleClick = e => {
    this.setState({
      current: e.key
    });
  };

  handleClickLanguage = e => {
    // global.language = e.key;
    // this.setState({
    //   selectedLan: global.language
    // });
    try {
      this.props.setString(e.key);
      reactLocalStorage.set("language", e.key);
    } catch (e) {
    } finally {
      console.log(
        `e.key: ${typeof e.key} \nthis.props: ${JSON.stringify(
          this.props.language,
          null,
          4
        )}`
      );
    }
  };

  componentDidMount() {
    // console.log(reactLocalStorage.get("language"));
    const language = reactLocalStorage.get("language");
    this.props.setString(language || 'ko');
  }

  render() {
    const { language, string } = this.props;
    const lanMenu = (
      <Menu
        onClick={this.handleClickLanguage}
        selectedKeys={[this.props.language]}>
        <Menu.Item key="ko">{string.lanString["ko"]}</Menu.Item>
        <Menu.Item key="en">{string.lanString["en"]}</Menu.Item>
        <Menu.Item key="cn">{string.lanString["cn"]}</Menu.Item>
      </Menu>
    );
    return (
      <nav className="navigation">
        <div className="navigation-container desk">
          <div className="white-text font-24 pointer user-select font-bold">
            <h1>
              <a href="/">
                <img
                  src={require("../img/common/logo.png")}
                  alt="blobs-logo"
                  className="responsive-img"
                />
              </a>
            </h1>
          </div>
          <Menu
            className="navigation-list"
            mode="horizontal"
            style={{
              backgroundColor: "transparent",
              color: "#fff",
              borderBottomWidth: 0
            }}
            onClick={this.handleClick}
            selectedKeys={[this.state.current]}>
            <Menu.Item>
              <Link to="/blobscoin">{string.menuBlobsCoin}</Link>
            </Menu.Item>
            <SubMenu
              title={
                <div className="submenu-title-wrapper">
                  <span>{string.menuBoard}</span> <Icon type="caret-down" />
                </div>
              }>
              <Menu.Item>
                <Link to="/board/NOTE">{string.menuBoardNotice}</Link>
              </Menu.Item>
              <Menu.Item>
                <Link to="/board/EVENT">{string.menuBoardEvent}</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              onClick={() => {
                window.open("http://flata.io");
              }}>
              <span>{string.menuFlata}</span>
            </Menu.Item>
            <Menu.Item className="select-language">
              <Dropdown overlay={lanMenu}>
                <div>
                  <span className="ant-dropdown-link">{`${string.lanString[language]}`}</span>
                  <Icon type="caret-down" />
                </div>
              </Dropdown>
            </Menu.Item>
          </Menu>
        </div>
        <div className="mobile navigation-container">
          <NavBar
            className="navbar"
            leftContent={
              <a href="/">
                <img
                  src={require("../img/common/logo_m.png")}
                  alt="blobs-logo"
                  className="responsive-img"
                />
              </a>
            }
            rightContent={
              <div
                className="pointer"
                onClick={() => {
                  this.setState({
                    drawerOpen: !this.state.drawerOpen
                  });
                }}>
                <Icon type="menu" />
              </div>
            }
            mode="light"
            style={{
              position: "relative",
              color: "#fff"
            }}></NavBar>
          {this.state.drawerOpen && (
            <div className="drawer">
              <Menu
                mode="inline"
                style={{
                  backgroundColor: "transparent",
                  color: "#fff",
                  borderBottomWidth: 0
                }}
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}>
                <Menu.Item>
                  <Link to="/blobscoin" className="white-text">
                    {string.menuBlobsCoin}
                  </Link>
                </Menu.Item>
                <SubMenu
                  title={
                    <div className="submenu-title-wrapper">
                      {string.menuBoard}
                    </div>
                  }>
                  <Menu.Item>
                    <Link to="/board/NOTE">{string.menuBoardNotice}</Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link to="/board/EVENT">{string.menuBoardEvent}</Link>
                  </Menu.Item>
                </SubMenu>
                <Menu.Item
                  onClick={() => {
                    window.open("http://flata.io");
                  }}>
                  <span>{string.menuFlata}</span>
                </Menu.Item>
              </Menu>
            </div>
          )}
          <div className="flex-row flex-end dropdown">
            <Dropdown overlay={lanMenu} className="select-language">
              <div className="flex-row flex-center flex-sb">
                <span className="ant-dropdown-link">{`${
                  string.lanString[this.props.language]
                }`}</span>
                <Icon type="caret-down" style={{ fontSize: "10px" }} />
              </div>
            </Dropdown>
          </div>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setString: lan => {
      dispatch(setLanguage(lan));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Navigation));
