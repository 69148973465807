import React, { Component } from "react";

export default class Sub5 extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="sub5 flex-row flex-1 flex-column">
        <div className="m-t-60 sub5-title">
          <span className="title font-bold white-text">
            {string.whitePaper}
          </span>
        </div>
        <div className="content">
          <div
            className="img-wrap pointer"
            onClick={() => {
              window.open("https://blobscoin.io/blobscoin_kr.pdf");
            }}>
            <img
              src={require("../../../img/main/sub5/ko-whitepaper.png")}
              alt="ko-whitepaper"
              className="responsive-img desk"
            />
            <img
              src={require("../../../img/main/sub5/ko-whitepaper_m.png")}
              alt="ko-whitepaper"
              className="responsive-img mobile"
            />
          </div>
          <div
            className="img-wrap pointer"
            onClick={() => {
              window.open("https://blobscoin.io/blobscoin_en.pdf");
            }}>
            <img
              src={require("../../../img/main/sub5/en-whitepaper.png")}
              alt="en-whitepaper"
              className="responsive-img desk"
            />
            <img
              src={require("../../../img/main/sub5/en-whitepaper_m.png")}
              alt="en-whitepaper"
              className="responsive-img mobile"
            />
          </div>
          <div
            className="img-wrap pointer"
            onClick={() => {
              window.open("https://blobscoin.io/blobscoin_cn.pdf");
            }}>
            <img
              src={require("../../../img/main/sub5/zh-whitepaper.png")}
              alt="zh-whitepaper"
              className="responsive-img desk"
            />
            <img
              src={require("../../../img/main/sub5/zh-whitepaper_m.png")}
              alt="zh-whitepaper"
              className="responsive-img mobile"
            />
          </div>
        </div>
      </div>
    );
  }
}
