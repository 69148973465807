const string_cn = {
  //语言
  lanCode: ["ko", "en", "zh"],
  lanString: {
    ko: "한국어",
    en: "English",
    cn: "中国人"
  },
  // 公告栏 分类
  boardCode: [1, 2, 3],
  boardString: {
    1: "公告",
    2: "活动",
    3: "新闻"
  },

  /******共同******/
  readyTo: "准备中.",
  noBoardList: "没有最近公告.",
  noEventList: "没有最近活动.",
  readyToVideo: "视频准备中",

  /******导航******/
  menuBlobsCoin: "Blobs Coin",
  menuBoard: "公告栏",
  menuBoardNotice: "公告",
  menuBoardEvent: "活动",
  menuFlata: "FLATA",

  /******intro******/
  whitePaper: "白皮书",
  blobsWhitePaper: "Blobs白皮书",
  blobsBlog: "Blobs 博客",
  blobsKakao: "Blobs KAKAO TALK",
  blobsTelegram: "Blobs TELEGRAM",
  blobsB2B: "Blobs B2B",
  blobsCard: "Blobs CARD",

  introTitle: "Blobs Coin(BLC)",
  introDescription: "以区块链为基础的服务平台",
  introDescription2: "提供迅捷及方便的区块链服务的平台",
  moreBtn: "更多",

  /******BLOBS COIN******/
  blobsPlatformTitle: "for Blobs 平台",
  blobsPlatformDesc:
    "尽管许多现有基金会都希望运营基于区块链的服务，但由于成本和各种问题，很难自行开发和维护DAPP，但是由于Blobs提供的平台提供了多种区块链功能，因此可以以低成本稳定区块链获得迅速的服务.",
  aboutUs: "About Us",

  aboutUsDesc:
    "Blobs于18年推出了卡片式冷钱包，目标是在ICT市场建立区块链技术中发挥关键作用，该技术通过创建有价值的区块链生态系统与人工智能，大数据和IoT等先进技术进行创新。 通过冷钱包开发将积累的区块链技术扩展到平台，正在开发可运用到各个行业的区块链平台，并通过FLATA与各种反向基金会合作.",
  flata:
    "*FLATA : 在WM holdings(MEET TOKEN基金会)创建的区块链生态系统，由各个行业的反向基金会组成.",

  blobsPlatform: "什么是Blobs平台?",
  blobsPlatformArticle:
    "Blobs平台旨在为各种基金会提供基础架构，以低成本轻松地应用区块链技术. 在初始版本中，将以PaaS形式总共提供四种服务：TOKEN钱包，合同管理，代管和伪造安全性，并且各种基金会都可以通过窃取BLOBS COIN来使用该平台. 于2018年起开始服务的BLOBS平台的BLOBS多功能冷钱包引擎为基础进行了扩张，可靠度高，若将一定量的BLOBS COIN进行STAKING，可获得免费服务.",
  blobsService: "Blobs服务",
  blobsServiceDesc:
    "Blobs直接运营的所有服务都将链接到该平台. 它包括已经投入使用的Cold Wallet以及计划于2019年开放的Blobs交易所和Blobs付款服务. 此外，由于Blobs平台可与较早引入的“ Flata”生态系统和加密货币互操作，因此blobs服务与参与“ Flata”生态系统的反向基金会的服务相互连接并可紧密地互相帮助. 比如，您可通过Blobs钱包开采的Blobs COIN为电动车充电，或将求职获得的MEET TOKEN通过Blobs付款系统购买礼品券.",
  blobsCoinDesc:
    "Blobs Coin目前以基于以太坊的ERC-20发布，但正在准备一个可以克服以太坊的性能延迟，渠道和治理方面的限制的主网. 多年来，Blobs计划通过Cold Wallet服务基于稳定的区块链引擎进行扩展和开发，并且预计与中国行业合作进行核心算法的研究和技术开发. Blobs COIN的主要用处如下.",

  blobsAPI: "使用Blobs平台的API",
  blobsAPIDesc:
    "服务使用限制因使用Blobs平台时所押的Blobs COIN数量而异. 另外，使用某些付费服务会消耗一定的Blobs COIN.",
  payService: "使用付款服务",
  payServiceDesc:
    " Blobs付款服务的基本付款方式是比特币和以太坊，但您也可以使用Blobs Coin进行付款. 若使用Blobs COIN进行付款，则免收服务费用.",

  flataCoop: "联动Flata",
  flataCoopDesc:
    "Blobs COIN是Flata（反向硬币生态系统）的一部分，可以与Flata的稳定COIN-FLAT进行交换。 因此，Blobs Coin始终可以通过Flat使用各种反向基金会提供的服务。.",
  blobsCoinMining: "开采Blobs COIN",
  blobsCoinMiningDesc:
    "Blobs COIN是根据Blobs平台和Blobs服务的使用量进行挖掘，并追加分发到市场。 此时，将已开采的Blob COIN部分空投给已经持有Blobs COIN的持有者。.",
  vision: "远景",
  profitShare: "收益分配",
  profitShareDesc1:
    "在不断发展的DAPP市场中，我们将提供一个环境，使所有一般开发人员都可以通过Blobs平台轻松结合区块链技术，从而创建一个平台生态系统.",
  profitShareDesc2:
    "随着平台的发展，我们将通过抛售现有持有者通过Blobs Coin及其服务产生的部分利润来提高Blobs Coin的持有价值。.",
  global: "扩展到全球",
  globalDesc:
    "我们将通过平台的推出，利用无边界货币区块链，积极进军海外市场. 为此，我们将在每个国家的交易所中上市Blobs COIN，以创建一个可以轻松在世界范围内交易的环境，以此为基础与当地各个行业进行合作，逐渐扩大平台规模.",
  coinDist: "分配COIN",
  coinDistDesc1: "Blobs COIN(BLC)的全部发行量为2000万个.",
  coinDistDesc2: "Blobs Coin销售筹集的资金将用于平台开发，销售和营销。.",
  coinDistDesc3: "为了激活Blobs平台，资金使用率可能会发生变化.",

  chartLabel1: "Point One",
  chartLabel2: "Point Two",
  chartLabel3: "Point Three",
  chartLabel4: "Point Four",

  roadMap: "ROAD MAP",
  roadMap1: "开发区块链主要程序块",
  roadMap2: "Blobs 多功能冷钱包 上市",
  roadMap3: "获得ISO 9001 品质经营认证",
  roadMap4: "正式成为韩国区块链协会会员",
  roadMap5: "开发区块链基础交易系统",
  roadMap6: "签订区块链中国合作伙伴MOU",
  roadMap7: "签订FLATA业务合作协议",
  roadMap8: "FLATA & Blobs 长远规划宣布仪式",
  roadMap9: "FLATA上市",
  roadMap10: "国际交易所上市",
  roadMap11: "国内大型交易所上市",
  roadMap12: "开启Blobs交易所",
  roadMap13: "Blobs平台测试版本上市",
  roadMap14: "发布Blobs主网白皮书",
  roadMap15: "开启Blobs交易服务",
  roadMap16: "Blobs平台正式版本上市",
  roadMap17: "进驻中国及东南亚市场",
  roadMap18: "开启Blobs主网测试版",
  roadMap19: "Blobs COIN 主网转换",
  roadMap20: "北美, 欧洲 等 扩展到全世界",
  roadMap21: "金融, 流通, 制造, 不动产 等 各种产业",
  roadMap22: "平台应用",

  /******成员******/
  boardMemberTitle: "Board member",
  advisorMeberTitle: "Advisor",
  member1: "Minwoo Kim",
  member1Desc1: "Blobs(株) 代表理事",
  member1Desc2: "(前) 三星SDS 研究员",
  member2: "Minjun Kim",
  member2Desc1: "Blobs(株) COO",
  member2Desc2: "(株)IT‘s IT 代表理事",
  member3: "Hakwoo Kim",
  member3Desc1: "Blobs(株) CMO",
  member3Desc2: "BIN WIN HEART 代表理事",
  member4: "Hyungseok Ryu",
  member4Desc1: "Blobs(株) CTO",
  member4Desc2: "(前) 三星SDS 研究员",
  member5: "Seunghak Lee",
  member5Desc1: "Blobs(株) CIO",
  member5Desc2: "(前) 三星SDS 研究员",
  member6: "Seunghyun Moon",
  member6Desc1: "Blobs(株) CCO",
  member6Desc2: "SARAM公司 代表理事",

  advisor1: "Heounsub Choi",
  advisor1Desc1: "(株) 公正的价值 COO",
  advisor1Desc2: "WM Holdings Limited CEO",
  advisor1Desc3: "(株) NEXON 战略合作(引资)",
  advisor1Desc4: "代驾搭配程序 开发策划",
  advisor1Desc5: "WORKMIT搭配程序 原始技术 策划人",
  advisor1Desc6: "UN和平志愿团 运营委员",
  advisor2: "Taesik Shin",
  advisor2Desc1: "韩世大学 保健融合研究生院 教授",
  advisor2Desc2: "(社) 新时代 韩国老人会 中央会 理事",
  advisor2Desc3: "UN世界和平志愿团 副总裁",
  advisor2Desc4: "亚太国际研究所 所长",
  advisor2Desc5: "(社) 韩国工伤保险学会 会长",
  advisor2Desc6: "(社) 韩国人事管理学会 副会长",
  advisor2Desc7: "授予越南勋章",
  advisor3: "Yeongdae Park",
  advisor3Desc1: "(社)新时代韩国老人会中央会 会长",
  advisor3Desc2: "(在)国技院 世界跆拳道 总裁",
  advisor3Desc3: "ROTC 中央会 特别委员长 (前) 大韩体育会合气道会长",
  advisor3Desc4: "(前) 国民生活体育 首尔市 会长",
  advisor3Desc5: "(前) 京畿大学法人 京畿航空旅行社代表",
  advisor3Desc6: "(前) 京畿大学 总 同门会长",
  advisor3Desc7: "学校法人 京畿大学 法人 社长",

  advisor4: "Eunsu Park",
  advisor4Desc1: "Flata TFT 本部长",
  advisor4Desc2: "LEADING投资证券 CISO",
  advisor5: "Dongho Lee",
  advisor5Desc1: "LEE DONGHO 法律事务所代表",
  advisor5Desc2: "(前) 国会委员 辅佐官",
  advisor6: "Yessica",
  advisor6Desc1: "BL财团 理事",
  advisor6Desc2: "(株) 公正的价值 CSO",
  advisor7: "Mira Kim",
  advisor7Desc1: "老年劳动组合 经济中部支部 女性委员长",
  advisor7Desc2: "(前) 京畿道社会企业培育委员",
  advisor8: "Dongkwon Cho",
  advisor8Desc1: "SB&G集团 会长",
  advisor8Desc2: "巨星集团 创业",
  advisor9: "Danny Choi",
  advisor9Desc1: "(株) 公正的价值 组长",
  advisor9Desc2: "MEET TOKEN 顾问",
  /******公告栏******/

  noticeDesc: "通知各种Blobs COING活动内容，公告，新闻等.",
  eventDesc: "Blobs COIN活动内容.",

  /******footer******/
  footerTitle: "Blobs Coin",
  company: "(株) Blobs",
  ceo: "代表 Ryu Hyungseok",
  email: "Email: support@blobs.kr",
  address: "5F, 2469, Nanbusunhwan-ro, Seocho-gu, Seoul",
  businessRegistration: "营业执照号码 883-88-01187",
  businessRegistration2: "通讯销售业申告 2020-首尔江南-00513",
  cs: "客服电话 : 02-423-4261(运营时间 : 10:00~18:00)"
};

export default string_cn;
