import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Icon } from "antd";
import { httpGet, httpUrl } from "../../api/httpclient";
import { connect } from "react-redux";

class Board extends Component {
  state = {
    boardList: [],
    selectedRow: parseInt(this.props.match.params.idx)
      ? parseInt(this.props.match.params.idx)
      : 0
  };

  getBoardList = () => {
    const { match } = this.props;
    httpGet(httpUrl.boardList, [10, 1, match.params.name], {}).then(res => {
      this.setState({
        boardList: res.data.data
      });
    });
  };

  componentDidUpdate(prevProps) {
    const { match } = this.props;
    if (prevProps.match.params.name !== match.params.name) {
      this.getBoardList();
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    console.log(JSON.stringify(this.props.match, null, 4));
    this.getBoardList();
  }

  render() {
    const { string } = this.props;
    return (
      <div className="container">
        <div className="board">
          {this.state.boardList.map(item => (
            <div className="board-item">
              <div
                className="question pointer"
                onClick={() => {
                  this.setState({
                    selectedRow:
                      this.state.selectedRow === item.idx ? 0 : item.idx
                  });
                }}>
                <div className="question-wrap flex-row">
                  <div className="icon">
                    {this.state.selectedRow === item.idx ? (
                      <Icon type="up" style={{ color: "#3afdfb" }} />
                    ) : (
                      <Icon type="down" />
                    )}
                  </div>
                  <div className="article">
                    <span>{`[${string.boardString[item.category]}] ${
                      item.title
                    }`}</span>
                  </div>
                </div>
              </div>
              {this.state.selectedRow === item.idx && (
                <div className="answer">
                  <div>
                    <span>{item.title}</span>
                  </div>
                  <span>{item.content}</span>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

export default connect(mapStateToProps)(withRouter(Board));
