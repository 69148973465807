import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import Intro from "../pages/main/Intro";
import BlobsCoin from "../pages/main/BlobsCoin";
import Board from "../pages/main/Board";
export default class Content extends Component {
  render() {
    return (
      <div id="content">
        <Switch>
          <Route exact path="/" component={Intro} />
          <Route exact path="/blobscoin" component={BlobsCoin} />
          <Route exact path="/board/:name" component={Board} />
          <Route exact path="/board/:name/:idx" component={Board} />
        </Switch>
      </div>
    );
    // return <div></div>;
  }
}
