const string_kr = {
  //언어
  lanCode: ["ko", "en", "zh"],
  lanString: {
    ko: "한국어",
    en: "English",
    cn: "中国人"
  },
  // 게시판 카테고리
  boardCode: [1, 2, 3],
  boardString: {
    1: "공지",
    2: "이벤트",
    3: "뉴스"
  },

  /******공통******/
  readyTo: "준비중 입니다.",
  noBoardList: "등록된 공지가 없습니다.",
  noEventList: "등록된 이벤트가 없습니다.",
  readyToVideo: "동영상 준비중",

  /******네비게이션******/
  menuBlobsCoin: "블랍스 코인",
  menuBoard: "게시판",
  menuBoardNotice: "공지사항",
  menuBoardEvent: "이벤트",
  menuFlata: "플랫타",

  /******intro******/
  whitePaper: "백서",
  blobsWhitePaper: "블락스 백서",
  blobsBlog: "블랍스 블로그",
  blobsKakao: "블랍스 카카오톡",
  blobsTelegram: "블랍스 텔레그램",
  blobsB2B: "블랍스 B2B",
  blobsCard: "블랍스 카드",

  introTitle: "BLOBS Coin(BLC)",
  introDescription: "Blockchain Based Service Platform",
  introDescription2: "Platform that provides easy and fast blockchain service",
  moreBtn: "MORE",

  /******블랍스 코인******/
  blobsPlatformTitle: "for 블랍스 플랫폼",
  blobsPlatformDesc:
    "기존의 많은 재단들은 블록체인 기반의 서비스를 운영하고 싶어도 비용 및 다양한 문제로 DAPP을 자체 개발하여 서비스 하기가 힘들었으나, 블랍스가 제공하는 플랫폼에서는 다양한 블록체인 기능들을 제공하기 때문에 저렴한 비용으로 안정적인 블록체인 서비스를 빠르게 확보할 수 있게 되었다.",
  aboutUs: "About Us",

  aboutUsDesc:
    "블랍스는 가치있는 블록체인 생태계 조성을 통해 인공지능, 빅데이터, IoT 등의 첨단기술로 혁신하고 있는 ICT시장에서 블록체인 기술이 자리잡는데 일익을 담당하겠다는 목표를 가지고 18년 카드형 콜드월렛 출시와 함께 출범하였습니다. 콜드월렛 개발을 통해 축척한 블록체인 기술을 플랫폼으로 확장하여 다양한 산업분야에서 활용 가능한 블록체인 플랫폼 개발을 추진하고 있으며, 플랫타*를 통해 다양한 리버스 재단들과 제휴를 추진하고 있습니다.",
  flata:
    "*플랫타 : WM holdings(밋토큰 재단)에서 조성하고 있는 블록체인 생태계로 다양한 영역의 사업을 영위하고 있는 리버스 재단들로 구성되어 있습니다.",

  blobsPlatform: "블랍스 플랫폼이란?",
  blobsPlatformArticle:
    "블랍스 플랫폼은 다양한 재단들이 저렵한 비용으로 쉽게 블록체인 기술을 적용할 수 있도록 인프라를 제공하는 것을 목표로 하고 있습니다. 초기 버전에는 토큰 지갑, 계약 관리, 에스크로, 위변조 보안 이렇게 총 4개의 서비스를 PaaS 형태로 제공할 예정이며 다양한 영역의 리버스 재단들은 블랍스코인을 스테이크하는 형태로 플랫폼을 이용할 수 있을 것입니다. 블랍스 플랫폼은 2018년부터 서비스 중인 블랍스 하이브리드 콜드월렛의 엔진을 기반으로 확장하였기 때문에 높은 안정성을 가지고 있으며, 일정량의 블랍스 코인 스테이크를 하는 경우 무료로 서비스를 제공하기 때문에 비용적인 측면에서 자유롭습니다.",
  blobsService: "블랍스 서비스",
  blobsServiceDesc:
    "블랍스에서 직접 운영하는 서비스는 모두 플랫폼과 연동하여 서비스 될 예정입니다. 이미 서비스 중인 콜드월렛을 비롯하여 2019년 중 오픈 예정인 블랍스 거래소, 블랍스 결제 서비스를 포함하고 있습니다. 또한 블랍스 플랫폼은 앞에서 소개한 “플랫타” 생태계와 암호화페를 통해 제약없이 연동되기 때문에 블랍스 서비스와 “플랫타” 생태계에 참여하고 있는 리버스 재단들의 서비스들은 서로 연결되어 강하게 상호작용합니다. 예를 들어 블랍스 지갑을 통해 채굴한 블랍스 코인을 가지고 전기 자동차의 배터리를 충전하거나 구인구직을 통해 받은 밋토큰을 가지고 블랍스 결제시스템을 통해 상품권을 구매하는 일 등이 가능해 집니다.",
  blobsCoinDesc:
    "블랍스 코인은 현재 이더리움 기반의 ERC-20으로 발행되었지만, 이더리움이 가지고 있는 성능지연, 수수로, 거버넌스 등의 한계를 극복할 수 있는 메인넷을 준비하고 있다. 블랍스가 다년간 콜드월렛 서비스를 통해 안정화를 거친 블록체인 엔진을 기반으로 확장하여 개발을 진행할 예정이며, 핵심 알고리즘에 대한 연구 및 기술개발은 중국 산학과 협업하여 추진 중 이다. 블랍스 코인의 주요 활용처는 아래와 같다.",

  blobsAPI: "블랍스 플랫폼 API 이용",
  blobsAPIDesc:
    "블랍스 플랫폼 이용 시 스테이크 하는 블랍스 코인의 양에 따라 서비스 사용량의 제한이 달라진다. 또한 일부 유료로 제공되는 서비스 이용 시 블랍스 코인을 소모하게 된다.",
  payService: "결제 서비스 이용",
  payServiceDesc:
    " 블랍스 결제 서비스의 기본 결제수단은 비트코인과 이더리움 이지만 블랍스 코인으로도 결제가 가능하다. 블랍스 코인으로 결제를 이용하는 경우 결제 수수료가 면제된다.",

  flataCoop: "플랫타 연계",
  flataCoopDesc:
    "블랍스 코인은 리버스 코인 생태계인 플랫타에 소속되어 있으며, 플랫타의 스테이블 코인인 플랫(FLAT)과 교환 가능하다. 따라서 블랍스 코인은 언제나 플랫을 통해 다양한 리버스 재단들이 제공하는 서비스를 이용할 수 있다.",
  blobsCoinMining: "블랍스 코인의 채굴",
  blobsCoinMiningDesc:
    "블랍스 코인은 블랍스 플랫폼과 블랍스 서비스의 이용량에 따라 채굴되어 시장에 추가로 유통된다. 이때 채굴된 블랍스 코인은 기존에 블랍스 코인을 보유하고 있는 홀더들에게 일부 에어드랍 된다.",
  vision: "비전",
  profitShare: "수익쉐어",
  profitShareDesc1:
    "지속적으로 성장하고 있는 DAPP 시장에서 블랍스 플랫폼을 통해 모든 일반 개발자들이 쉽게 블록체인 기술을 접목할 수 있는 환경을 제공하여 플랫폼 생태계를 만들어 나갈 것입니다.",
  profitShareDesc2:
    "플랫폼의 성장과 함께 채굴되는 블랍스 코인 및 자체 서비스를 통해 발생하는 수익의 일부를 기존 홀더들에게 에어드랍 함으로써 블랍스 코인의 보유 가치를 제고할 것입니다.",
  global: "글로벌 확장",
  globalDesc:
    "국경없는 화폐라는 블록체인의 장점을 살려 플랫폼 출시화 함께 해외시장 진출을 적극적으로 추진할 예정입니다. 이를 위해 각국의 거래소에 블랍스 코인을 상장하여 세계 곳곳에서 쉽게 거래를 할 수 있는 환경을 조성하고, 기반으로 다양한 현지 업체들과 제휴를 통해 플랫폼의 규모를 지속적으로 키워 나갈 것 입니다.",
  coinDist: "코인의 분배",
  coinDistDesc1: "블랍스 코인(BLC)의 전체 발행 수는 2000만개입니다.",
  coinDistDesc2:
    "블랍스 코인의 판매로 마련된 자금은 플랫폼개발 및 영업, 마케팅 등에 사용될 예정입니다.",
  coinDistDesc3:
    "자금사용 비율은 향후 블랍스 플랫폼의 활성화를 위하여 조정이 필요한 경우 변경될 수 있습니다.",

  chartLabel1: "Point One",
  chartLabel2: "Point Two",
  chartLabel3: "Point Three",
  chartLabel4: "Point Four",

  roadMap: "로드맵",
  roadMap1: "블록체인 코어모듈 연구개발",
  roadMap2: "블랍스 하이브리드 콜드월렛 출시",
  roadMap3: "ISO 9001 품질 경영 인증 획득",
  roadMap4: "한국 블록체인 협회 정회원 등록",
  roadMap5: "블록체인 기반 결제 시스템 개발",
  roadMap6: "블록체인 중국 파트너 MOU 체결",
  roadMap7: "플랫타 업무 제휴 협약 체결",
  roadMap8: "플랫타 & 블랍스 비전선포식",
  roadMap9: "플랫타 상장",
  roadMap10: "글로벌 거래소 상장",
  roadMap11: "국내 대형 거래소 상장",
  roadMap12: "블랍스 거래소 오픈",
  roadMap13: "블랍스 플랫폼 베타 버전 출시",
  roadMap14: "블랍스 메인넷 백서 발표",
  roadMap15: "블랍스 결제 서비스 오픈",
  roadMap16: "블랍스 플랫폼 정식버전 출시",
  roadMap17: "중국 및 동남아 시장 진출",
  roadMap18: "블랍스 메인넷 베타 버전 출시",
  roadMap19: "블랍스 코인 메인넷 전환",
  roadMap20: "북미, 유럽 등 전세계로 시장 확장",
  roadMap21: "금융, 유통, 제조, 부동산 등 다양한 산업에",
  roadMap22: "플랫폼 적용",

  /******멤버******/
  boardMemberTitle: "Board member",
  advisorMeberTitle: "Advisor",
  member1: "Minwoo Kim",
  member1Desc1: "블랍스(주) 대표이사",
  member1Desc2: "(전) 삼성SDS 연구원",
  member2: "Minjun Kim",
  member2Desc1: "블랍스(주) COO",
  member2Desc2: "(주)잇츠아이티 대표이사",
  member3: "Hakwoo Kim",
  member3Desc1: "블랍스(주) CMO",
  member3Desc2: "빅윈하트 대표이사",
  member4: "Hyungseok Ryu",
  member4Desc1: "블랍스(주) CTO",
  member4Desc2: "(전) 삼성SDS 연구원",
  member5: "Seunghak Lee",
  member5Desc1: "블랍스(주) CIO",
  member5Desc2: "(전) 삼성SDS 연구원",
  member6: "Seunghyun Moon",
  member6Desc1: "블랍스(주) CCO",
  member6Desc2: "사람컴퍼니 대표이사",

  advisor1: "Heounsub Choi",
  advisor1Desc1: "(주) 공정한 가치 COO",
  advisor1Desc2: "WM Holdings Limited CEO",
  advisor1Desc3: "(주) 넥슨 전략적 제휴(투자유치)",
  advisor1Desc4: "대리운전 매칭프로그램 개발기획",
  advisor1Desc5: "워크밋 매칭프로그램 원천기술 기획자",
  advisor1Desc6: "UN평화봉사단 운영위원",
  advisor2: "Taesik Shin",
  advisor2Desc1: "한세대학교 보건융합대학원 교수",
  advisor2Desc2: "(사) 새시대 한국노인회 중앙회 이사",
  advisor2Desc3: "UN세계평화봉사단 부총재",
  advisor2Desc4: "아태국제연구소 소장",
  advisor2Desc5: "(사) 한국산재보험학회 회장",
  advisor2Desc6: "(사) 한국인사관리학회 부회장",
  advisor2Desc7: "베트남 훈장 수여",
  advisor3: "Yeongdae Park",
  advisor3Desc1: "(사)새시대 한국노인회 중앙회 회장",
  advisor3Desc2: "(재)국기원 세계태권도 총재",
  advisor3Desc3: "ROTC 중앙회 특별위원장 (전) 대한체육회합기도회장",
  advisor3Desc4: "(전) 국민생활체육 서울시 회장",
  advisor3Desc5: "(전) 경기대학법인경기항공여행사 대표",
  advisor3Desc6: "(전) 경기대학교 총 동문회장",
  advisor3Desc7: "학교법인 경기대학교 법인 사장",

  advisor4: "Eunsu Park",
  advisor4Desc1: "Flata TFT 본부장",
  advisor4Desc2: "리딩투자증권 CISO",
  advisor5: "Dongho Lee",
  advisor5Desc1: "이동호 법률사무소 대표",
  advisor5Desc2: "(전) 국회의원 보좌관",
  advisor6: "Yessica",
  advisor6Desc1: "BL재단 이사",
  advisor6Desc2: "(주) 공정한가치 CSO",
  advisor7: "Mira Kim",
  advisor7Desc1: "시니어노동조합 경기중부지부 여성위원장",
  advisor7Desc2: "(전) 경기도 사회적 기업 육성위원",
  advisor8: "Dongkwon Cho",
  advisor8Desc1: "SB&G그룹 회장",
  advisor8Desc2: "거성그룹 창업",
  advisor9: "Danny Choi",
  advisor9Desc1: "(주) 공정한가치 팀장",
  advisor9Desc2: "밋토큰 컨설턴트",
  /******게시판******/

  noticeDesc:
    "블랍스 코인 활동 내역, 공지사항, 언론보도, 행사 등 알려드립니다.",
  eventDesc: "블랍스 코인 이벤트 내역입니다.",

  /******footer******/
  footerTitle: "BLOBS Coin",
  company: "(주) 블랍스",
  ceo: "대표 유형석",
  email: "Email: support@blobs.kr",
  address: "서울 서초구 남부순환로 2469 도요빌딩 4층",
  businessRegistration: "사업자등록번호 883-88-01187",
  businessRegistration2: "통신판매업신고 2020-서울강남-00513호",
  cs: "고객센터 : 02-423-4261(운영시간 : 10:00~18:00)"
};

export default string_kr;
