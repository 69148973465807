import React, { Component } from "react";
import { connect } from "react-redux";

class HeaderCoin extends Component {
  render() {
    const { string } = this.props;
    return (
      <div className="header-coin flex-column white-text justify-center">
        <section className="header-coin__title desk">
          <p>
            {string.introTitle} <br /> {string.footerTitle}
            <br /> {`${string.blobsPlatformTitle}`}
          </p>
        </section>
        <section className="header-coin__title mobile">
          <p>
            {string.introTitle} <br /> {string.footerTitle}
            {`${string.blobsPlatformTitle}`}
          </p>
        </section>
        <div className="header-coin__body">
          <span>{`${string.blobsPlatformDesc}`}</span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

export default connect(mapStateToProps)(HeaderCoin);
