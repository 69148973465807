const string_en = {
  //language
  lanCode: ["ko", "en", "zh"],
  lanString: {
    ko: "한국어",
    en: "English",
    cn: "中国人"
  },
  // Notice board category
  boardCode: [1, 2, 3],
  boardString: {
    1: "Notice",
    2: "Event",
    3: "News"
  },

  /******common******/
  readyTo: "Coming soon.",
  noBoardList: "There is no registered notice.",
  noEventList: "There are no registered events.",
  readyToVideo: "Videos are coming soon",

  /******Navigation******/
  menuBlobsCoin: "Blobs Coin",
  menuBoard: "notice board",
  menuBoardNotice: "Notice",
  menuBoardEvent: "Event",
  menuFlata: "Flata",

  /******intro******/
  whitePaper: "whitepaper",
  blobsWhitePaper: "Blobs whitepaper",
  blobsBlog: "Blobs blog",
  blobsKakao: "Blobs kakaotalk",
  blobsTelegram: "Blobs telegram",
  blobsB2B: "Blobs B2B",
  blobsCard: "Blobs card",

  introTitle: "BLOBS Coin(BLC)",
  introDescription: "Blockchain Based Service Platform",
  introDescription2: "Platform that provides easy and fast blockchain service",
  moreBtn: "MORE",

  /******Blobs coin******/
  blobsPlatformTitle: "for Blobs platform",
  blobsPlatformDesc:
    "Although many existing foundations wanted to operate a blockchain-based service, it was difficult to develop and service DAPPs on their own due to cost and various problems.However, the platform provided by Blobs provides a variety of blockchain functions to provide stable fast blockchain service at low cost.",
  aboutUs: "About Us",

  aboutUsDesc:
    "Blobs aims to play a part in establishing blockchain technology in the ICT market, which is innovating with cutting-edge technologies such as artificial intelligence, big data, and IoT by creating a valuable blockchain ecosystem. And we launched the card-type cold wallet and Blobs at the same time in 2018. We are developing blockchain platforms that can be used in various industries by expanding the blockchain technology that has been accumulated through the development of cold wallets, and promoting partnerships with various reverse foundations through Flata *.",
  flata:
    "*Flata : It is a blockchain ecosystem created by WM holdings (Meet Token Foundation) and consists of reverse foundations that carry out various areas of business.",

  blobsPlatform: "What is Blobs platform?",
  blobsPlatformArticle:
    "Blobs platform aims to provide the infrastructure for various foundations to easily apply blockchain technology at a low cost. In the initial version, a total of four services will be provided in the form of PaaS: token wallet, contract management, escrow, and forgery security, and various foundations will be able to use the platform by staking Blobs Coins. The Blobs platform has expanded on the engine of the Blobs Hybrid Cold Wallet, which has been in service since 2018., it has high stability. Free of charge if you have a certain amount of Blobs Coin Stake, which is free of cost.",
  blobsService: "Blobs service",
  blobsServiceDesc:
    "All services directly operated by Blobs will be linked to the platform.It includes Cold Wallet, which is already in service, as well as Blobs Exchange and Blobs Payment Services, which are scheduled to open in 2019. In addition, since the Blobs platform is interoperable with the “Flata” ecosystem and cryptocurrency introduced earlier, the services of the Blobs service and the reverse foundations participating in the “Flata” ecosystem are interconnected and strongly interacted. For example, you can charge your electric vehicle's battery with Blobs coin mined through your Blobs wallet, or you can buy gift certificates through the Blobs payment system with Meet token received through job listings.",
  blobsCoinDesc:
    "Blobs Coin is currently issued as Ethereum-based ERC-20, but we are preparing a mainnet that can overcome the limitations of Ethereum's performance delay, channel, and governance.Blobs will expand and develop based on the stabilized blockchain engine through the Cold Wallet service for many years.Also  research and technology development on core algorithms are being carried out in cooperation with Chinese industry. The main uses of Blobs Coin are as follows.",

  blobsAPI: "Use the Blobs Platform API",
  blobsAPIDesc:
    "When using the Blobs platform, the amount of service usage is limited by the amount of Blobs coins to be staked.In addition, some paid services will consume blobs coins.",
  payService: "Payment service",
  payServiceDesc:
    " The basic payment methods of the Blobs payment service are Bitcoin and Ethereum, but you can also pay with Blobs Coin. Payment fees are waived when using payment with Blobs Coin.",

  flataCoop: "Flata linkage",
  flataCoopDesc:
    "Blobs Coin is a part of Flata, a reverse coin ecosystem, and can be exchanged for Flata's stable coin, Flat (FLAT).Therefore, you will always be able to use Blobs Coin get the services provided by various reverse foundations through Flat.",
  blobsCoinMining: "Mining Blobs Coins",
  blobsCoinMiningDesc:
    "Blobs coins are mined according to the usage of Blobs platform and Blobs services and further distributed to the market. At this time, mined Blobs Coin is partially airdropped to holders that already have Blobs Coin.",
  vision: "Vision",
  profitShare: "Revenue Share",
  profitShareDesc1:
    "In the ever-growing DAPP market, Blobs platform will create a platform ecosystem by providing an environment where all general developers can easily integrate blockchain technology.",
  profitShareDesc2:
    "We will increase the value of Blobs Coin's holdings by airdropping existing holders a portion of the profits generated through Blobs Coin and its own services mined with the growth of the platform.",
  global: "Global expansion",
  globalDesc:
    "Taking advantage of the blockchain, the borderless currency, we will actively promote the overseas market with the launch of the platform.To this end, we will list Blobs Coins on exchanges in each country to create an environment where transactions can be easily carried out around the world, and continue to grow the scale of the platform through partnerships with various local companies.",
  coinDist: "Coin Distribution",
  coinDistDesc1: "The total number of Blobs Coins (BLC) is 20 million.",
  coinDistDesc2:
    "The funds raised from the sale of Blobs Coin will be used for platform development, sales and marketing.",
  coinDistDesc3:
    "Funding rates may change if adjustments are needed to activate the Blobs platform in the future.",

  chartLabel1: "Point One",
  chartLabel2: "Point Two",
  chartLabel3: "Point Three",
  chartLabel4: "Point Four",

  roadMap: "Roadmap",
  roadMap1: "Blockchain Core Module R&D",
  roadMap2: "Launched Blobs Hybrid Cold Wallet",
  roadMap3: "Acquired ISO 9001 Quality Management Certification",
  roadMap4: "Registered as a regular member of Korea Blockchain Association",
  roadMap5: "Development of blockchain based payment system",
  roadMap6: "Signed MOU with Blockchain Chinese Partner",
  roadMap7: "Signed a partnership agreement with Flata",
  roadMap8: "Flata & Blobs Vision Declaration Ceremony",
  roadMap9: "Flata Listed",
  roadMap10: "Listed on the global exchange",
  roadMap11: "Listed on the domestic large exchange",
  roadMap12: "Launch Blobs Exchange",
  roadMap13: "Release Blobs Platform Beta",
  roadMap14: "Announce Blobs Mainnet White Paper",
  roadMap15: "Launch Blobs payment service",
  roadMap16: "Release full version of Blobs platform",
  roadMap17: "Enter Chinese and Southeast Asian markets",
  roadMap18: "ReleaseBlobs mainnet beta",
  roadMap19: "Switch Blobs coin mainnet",
  roadMap20: "Market expansion worldwide, including North America and Europe",
  roadMap21:
    "Various industries such as finance, distribution, manufacturing, real estate",
  roadMap22: "Apply to Platform",

  /******Members******/
  boardMemberTitle: "Board member",
  advisorMeberTitle: "Advisor",
  member1: "Minwoo Kim",
  member1Desc1: "Blobs Corp. CEO",
  member1Desc2: "(Former) Samsung SDS Researcher",
  member2: "Minjun Kim",
  member2Desc1: "Blobs Corp. COO",
  member2Desc2: "It's IT Corp. CEO",
  member3: "Hakwoo Kim",
  member3Desc1: "Blobs Corp. CMO",
  member3Desc2: "Big Win Heart CEO",
  member4: "Hyungseok Ryu",
  member4Desc1: "Blobs Corp. CTO",
  member4Desc2: "(Former) Samsung SDS Researcher",
  member5: "Seunghak Lee",
  member5Desc1: "Blobs Corp. CIO",
  member5Desc2: "(Former) Samsung SDS Researcher",
  member6: "Seunghyun Moon",
  member6Desc1: "Blobs Corp. CCO",
  member6Desc2: "Saram Company CEO",

  advisor1: "Heounsub Choi",
  advisor1Desc1: "Fairvalue Corp. COO",
  advisor1Desc2: "WM Holdings Limited CEO",
  advisor1Desc3: "Nexon Corp. Strategic Alliance (investment attraction)",
  advisor1Desc4:
    "Development and Planning of designated driver Matching Program",
  advisor1Desc5: "Workmit Matching Program Source Technology Planner",
  advisor1Desc6: "Member of the UN Peace Corps",
  advisor2: "Taesik Shin",
  advisor2Desc1:
    "Professor, Graduate School of Health Convergence, Hanse University",
  advisor2Desc2: "Director of the New Age Korean Elderly Association",
  advisor2Desc3: "Vice President of the UN World Peace Corps",
  advisor2Desc4: "Director, Asia Pacific Institute",
  advisor2Desc5: "President, Korea Industrial Accident Insurance Association",
  advisor2Desc6: "Vice Chairman, Korean Society of Human Resources Management",
  advisor2Desc7: "Awarded Vietnam Medal",
  advisor3: "Yeongdae Park",
  advisor3Desc1: "President of the New Age Korean Elderly Association",
  advisor3Desc2: "President, Kukkiwon World Taekwondo",
  advisor3Desc3:
    "Chairman of ROTC General Assembly (Former) Korea Sports Association Prayer Chairperson",
  advisor3Desc4: "(Former) National Sports & Sports Chairman",
  advisor3Desc5: "(Former) Gyeonggi University Gyeonggi Air Travel Agency",
  advisor3Desc6: "(Former) Kyonggi University Alumni Chairman",
  advisor3Desc7: "President, Kyonggi University Corporation",

  advisor4: "Eunsu Park",
  advisor4Desc1: "Flata TFT General manager",
  advisor4Desc2: "Leading Investment Securities CISO",
  advisor5: "Dongho Lee",
  advisor5Desc1: "Dong Ho Lee Law Office CEO",
  advisor5Desc2: "(Former) Legislator's Assistant",
  advisor6: "Yessica",
  advisor6Desc1: "BL Foundation Director",
  advisor6Desc2: "Fairvalue Corp.CSO",
  advisor7: "Mira Kim",
  advisor7Desc1: "Senior Labor Union Women's Chairman, Gyeonggi Central Branch",
  advisor7Desc2: "(Former) Gyeonggi-do Social Enterprise Development Committee",
  advisor8: "Dongkwon Cho",
  advisor8Desc1: "SB&G Group Chairman",
  advisor8Desc2: "Founded Geosung Group",
  advisor9: "Danny Choi",
  advisor9Desc1: "Fairvalue Corp. Team Leader",
  advisor9Desc2: "Meet Token Consultant",
  /******notice board******/

  noticeDesc:
    "Note about Blobs coin activities, announcements, press releases, events, and more.",
  eventDesc: "Blobs coin event history.",

  /******footer******/
  footerTitle: "BLOBS Coin",
  company: "Blobs Corp.",
  ceo: "President Hyung-seok Ryu",
  email: "Email: support@blobs.kr",
  address: "4F, 2469, Nanbusunhwan-ro, Seocho-gu, Seoul",
  businessRegistration: "corporate registration number 883-88-01187",
  businessRegistration2:
    "Communication sales business report 2020-Seoul gangnam-00513",
  cs: "Service center : 02-423-4261(operating time : 10:00~18:00)"
};

export default string_en;
