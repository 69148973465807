import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import string_kr from "../../string_kr";
import { connect } from "react-redux";

class HeaderBoard extends Component {
  componentDidMount() {
    console.log(JSON.stringify(this.props, null, 4));
  }
  render() {
    const { string } = this.props;
    return (
      <div className="header-board flex-row flex-center flex-column white-text justify-center">
        <section className="header-board__title p-b-20">
          <span className="font-bold ">
            {this.props.match.params.name === "NOTE"
              ? `${string.menuBoardNotice}`
              : `${string.menuBoardEvent}`}
          </span>
        </section>
        <div className="header-board__body">
          <span>
            {this.props.match.params.name === "NOTE"
              ? `${string.noticeDesc}`
              : `${string.eventDesc}`}
          </span>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    language: state.setString.language,
    string: state.setString.string
  };
};

export default connect(mapStateToProps)(withRouter(HeaderBoard));
