import React, { Component } from "react";
import { connect } from "react-redux";

class Footer extends Component {
  render() {
    const { language, string } = this.props;
    return (
      <footer id="footer">
        <div className="container">
          <div className="footer-container">
            <div className="company-info flex-row flex-column">
              <div>
                <span className="blobs-coin-title">{string.footerTitle}</span>
              </div>
              <div className="m-t-3 m-b-2">
                <span>{string.company}</span>
                <span className="m-x-2">|</span>
                <span>{string.ceo}</span>
              </div>
              <div className="m-b-2">
                <span>{string.address}</span>
              </div>
              <div className="m-b-3">
                <span>
                  {string.businessRegistration}
                  <span className="m-x-2 desk">|</span>
                  <br className="mobile" />
                  {string.businessRegistration2}
                </span>
              </div>
              <div>
                <strong>
                  <span>
                    {string.email}
                    <span className="m-x-2 desk">|</span>
                    <br className="mobile" />
                    {string.cs}
                  </span>
                </strong>
              </div>
            </div>
            <div className="flex-row flex-column right">
              <div className="flex-row flex-sb m-b-5">
                <div
                  className="link-img pointer"
                  onClick={() => {
                    window.open("http://blog.naver.com/blobs");
                  }}>
                  <img
                    src={require("../img/icon/blog_b.png")}
                    alt={`blobs_blog_link_btn`}
                    className="responsive-img desk"
                  />
                  <img
                    src={require("../img/icon/blog_b_m.png")}
                    alt={`blobs_blog_link_btn`}
                    className="responsive-img mobile"
                  />
                </div>
                <div
                  className="link-img pointer"
                  onClick={() => {
                    window.open("https://open.kakao.com/o/gzMTghPb");
                  }}>
                  <img
                    src={require("../img/icon/kakao_b.png")}
                    alt={`blobs_kakao_link_btn`}
                    className="responsive-img desk"
                  />
                  <img
                    src={require("../img/icon/kakao_b_m.png")}
                    alt={`blobs_kakao_link_btn`}
                    className="responsive-img mobile"
                  />
                </div>
                <div
                  className="link-img pointer"
                  onClick={() => {
                    window.open("https://t.me/blobscoin");
                  }}>
                  <img
                    src={require("../img/icon/telegram_b.png")}
                    alt={`blobs_telegram_link_btn`}
                    className="responsive-img desk"
                  />
                  <img
                    src={require("../img/icon/telegram_b_m.png")}
                    alt={`blobs_telegram_link_btn`}
                    className="responsive-img mobile"
                  />
                </div>
                <div
                  className="link-img pointer"
                  onClick={() => {
                    window.open("https://solution.blobs.co.kr/");
                  }}>
                  <img
                    src={require("../img/icon/B2B_b.png")}
                    alt={`blobs_b2b_link_btn`}
                    className="responsive-img desk"
                  />
                  <img
                    src={require("../img/icon/B2B_b_m.png")}
                    alt={`blobs_b2b_link_btn`}
                    className="responsive-img mobile"
                  />
                </div>
                <div
                  className="link-img pointer"
                  onClick={() => {
                    window.open("https://card.blobs.co.kr/");
                  }}>
                  <img
                    src={require("../img/icon/blobscard_b.png")}
                    alt={`blobs_card_link_btn`}
                    className="responsive-img desk"
                  />
                  <img
                    src={require("../img/icon/blobscard_b_m.png")}
                    alt={`blobs_card_link_btn`}
                    className="responsive-img mobile"
                  />
                </div>
              </div>
              <div className="flex-row flex-end">
                <div className="flex-row">
                  <div className="flex-row flex-center justify-center">
                    <img
                      src={require("../img/icon/association.png")}
                      alt="block-chain-association"
                      className="responsive-img association-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    language: state.setString.language,
    string: state.setString.string,
  };
};

export default connect(mapStateToProps)(Footer);
